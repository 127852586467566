import firebase from "firebase";
const fireBaseConfig = {
    apiKey: "AIzaSyAq9PiYM9E70VGDFXIwlkupZAY2Fm0WgNE",
    authDomain: "metroplex-cricket-club.firebaseapp.com",
    databaseURL: "https://metroplex-cricket-club.firebaseio.com",
    projectId: "metroplex-cricket-club",
    storageBucket: "metroplex-cricket-club.appspot.com",
    messagingSenderId: "519400588452"
  };
const firebaseApp = firebase.initializeApp(fireBaseConfig);
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const googleProvider = new firebase.auth.GoogleAuthProvider();
const emailAuthProvider = firebase.auth.EmailAuthProvider();
const firestore = firebase.firestore();
firebase.auth().useDeviceLanguage();
export {firebaseApp, facebookProvider, googleProvider, emailAuthProvider, fireBaseConfig, firestore}

