import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { firebaseApp } from '../../firebaseService';
import {AuthContext} from '../../App';
function PrivateRoute ({ component: Component, ...rest }) {
    const {state: authState} = React.useContext(AuthContext);
    return (
    <Route 
        {...rest}
        render={(props) => (
            authState.isAuthenticated
                ? <Component {...props} />
                : <Redirect to={
                    {
                        pathname: '/login',
                        state: { from: props.location }
                    }
                }

                />
        )}
    />)
}
export default PrivateRoute;