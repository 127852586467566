import React from 'react';
import { ListItem, ListItemText, ListItemAvatar, Avatar } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';

function PlayerAvailabilityListItem(props) {
    const {playerName, playerPhotoURL} = props;
    return (
        <ListItem>
            <ListItemAvatar>
                {
                    playerPhotoURL
                        ? <Avatar src={playerPhotoURL} />
                        : <Avatar>
                            <PersonIcon />
                        </Avatar>
                }
            </ListItemAvatar>
            <ListItemText primary={playerName} />
        </ListItem>
    );
}

export default PlayerAvailabilityListItem