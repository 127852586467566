import React, { useState } from 'react';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { withRouter } from 'react-router-dom';

function LoginDialog(props) {
    // Configure FirebaseUI.
    const { uiConfig, dialogState, fullScreen } = props;
    const [openDialog, setOpenDialog] = useState(dialogState);
    function handleClose(event) {
        //do something
        setOpenDialog(false);
        props.history.push('/');
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="login-dialog-title">
            <DialogTitle id="login-dialog-title">Please sign in!</DialogTitle>
            <DialogContent>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </DialogContent>
        </Dialog>
    )
}

export default withMobileDialog()(withRouter(LoginDialog));
