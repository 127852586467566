import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, Typography, Divider, CardActions, Button, Collapse, Badge, List, ListSubheader }
    from '@material-ui/core';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Check from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import EventsService from './events-service';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import classnames from 'classnames';
import PlayerAvailabilityListItem from './player-availability-list-item';
import useEventAvailability from '../../hooks/data/UseEventAvailability';
import { AuthContext } from "../../App";
import EventDialog from './event-dialog';
const styles = (theme) => ({
    card: {
        //width: '100%',
        minWidth: 400,
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    margin: {
        margin: theme.spacing(2),
        //marginRight: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
    },
});
function EventCard(props) {
    const { event, classes, saveEvent } = props;
    const [availability, setAvailability] = useState({})
    const [expandAvailability, setExpandAvailability] = useState(false);
    const { availableList, notAvailableList } = useEventAvailability(event.id);
    const { state: authState } = React.useContext(AuthContext);
    const [editPopup, setEditPopup] = useState(false);
    const theme = useTheme();
    useEffect(() => {
        EventsService.getMyEventAvailability(authState.user.id, event.id)
            .then((availability) => setAvailability(availability))
    }, {})

    let title;
    switch (event.type) {
        case "match":
            title = event.team.concat(" vs. ").concat(event.opponent);
            break;
        case "practice":
            title = "Practice";
            break;
        default:
            title = event.type;
    }

    function handleAvailable() {
        EventsService.setAvailability(authState.user, event, availability, true)
            .then((updated) => setAvailability(updated));
    }

    function handleNotAvailable() {
        EventsService.setAvailability(authState.user, event, availability, false)
            .then((updated) => setAvailability(updated));
    }

    function handleExpandAvailability() {
        setExpandAvailability(!expandAvailability);
    }

    function handleSaveEvent(eventData){
        setEditPopup(false);
        saveEvent(eventData);
    }
    
    return <Card className={classes.card}>

        <CardHeader
            title={title}
            action={
                authState.allowAddEvent ?
                    <IconButton aria-label="edit">
                        <EditIcon onClick={() => setEditPopup(true)} />
                        <EventDialog event={event} open={editPopup} fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
                            handleClose={() => setEditPopup(false)}
                            handleSaveEvent={(eventData) => handleSaveEvent(eventData)} />
                    </IconButton>
                    : ''
            } />
        <Divider />
        <CardContent>
            <Typography component="div" variant="subtitle1">
                <strong>When: </strong> {event.when.toDate().toLocaleString("en-US")}
            </Typography>
            <Typography component="div" variant="subtitle1">
                <strong>Where: </strong> {event.where}
            </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
            <Badge badgeContent={availableList.length} color="primary">
                <Button variant="outlined" color="primary" onClick={handleAvailable}>
                    {(!!availability) && availability.available ? <Check /> : ''}
                    Available
                </Button>
            </Badge>
            <Badge badgeContent={notAvailableList.length} color="secondary">
                {/* <ThumbDownIcon /> */}
                <Button variant="outlined" color="secondary" onClick={handleNotAvailable}>
                    {(!!availability) && (!availability.available) ? <Check /> : ''}
                    Not Available
                </Button>
            </Badge>
            <IconButton
                className={classnames(classes.expand, {
                    [classes.expandOpen]: expandAvailability,
                })}
                onClick={handleExpandAvailability}
                aria-expanded={expandAvailability}
                aria-label="Show more"
            >
                <ExpandMoreIcon />
            </IconButton>
        </CardActions>
        <Collapse in={expandAvailability} timeout="auto" unmountOnExit>
            <Divider />
            <CardContent>
                <List>
                    <ListSubheader color="primary">Available</ListSubheader>
                    {availableList.map((item) =>
                        <PlayerAvailabilityListItem key={item.id} {...item} />
                    )}
                </List>
            </CardContent>
            <Divider />
            <CardContent>
                <List><ListSubheader color="inherit">Not Available</ListSubheader>
                    {notAvailableList.map((item) =>
                        <PlayerAvailabilityListItem key={item.id} {...item} />
                    )}
                </List>
            </CardContent>
        </Collapse>
    </Card>
}

export default withStyles(styles)(EventCard)