import React, { useState } from 'react';
//import useFormInput from '../../hooks/UseFormInput';
import { useFormState } from 'react-use-form-state';
import { withRouter } from 'react-router-dom';
import { firestore, firebaseApp } from '../../firebaseService';
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button, TextField} from '@material-ui/core';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {AuthContext} from '../../App';
function ActivationCode(props) {
    //const activationCode = useFormInput();
    const [formState, { text }] = useFormState();
    const [invalidCode, setInvalidCode] = useState(false);
    const [modalOpen, setModalOpen] = useState(props.open);
    const {fullScreen} = props;
    const {dispatch} = React.useContext(AuthContext);

    function handleClose() {
        props.history.push('/');
    }

    function handleSubmit(e) {
        //e.preventDefault();
        firestore.collection("codes").doc(formState.values.activationCode).get().then((doc) => {
            if (doc.exists) {
                setInvalidCode(false);
                //create player
                let user = firebaseApp.auth().currentUser;
                let playerData = {
                    activatedWith: formState.values.activationCode,
                    displayName: user.displayName,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    photoURL: user.photoURL
                };
                firestore.collection("players").doc(user.uid)
                    .set(playerData)
                    .then(function () {
                        console.log('Player added successfully.');
                    })
                    .catch((error) => console.log('Error adding player: ', error));
                //set current player in state
                // setCurrentPlayer(user.uid, { id: user.uid, ...playerData });
                dispatch({
                    type: "LOGIN",
                    payload: { id: user.uid, ...playerData }
                });
                //close the popup
                setModalOpen(false);
            } else {
                //show error message
                console.log('invalid activation code')
                setInvalidCode(true);
            }
        }).catch((error) => console.log('Error getting code from database:', error))
    }
    return (
        <Dialog
            fullScreen={fullScreen}
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="activation-code-form"
        >
            <DialogTitle id="activation-code-form">Activate Your Account</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter the activation code that you received from the club administration.
                </DialogContentText>
                <TextField autoFocus fullWidth margine="dense" 
                    id="activation-code" 
                    label='Activation Code' 
                    {...text('activationCode')} />
                {/* {invalidCode ?
                    <SnackbarContent
                        aria-describedby="activation-result-message"
                        error
                        header='Invalid Activation Code'
                        message={
                            <span id="activation-result-message" className={classes.message}>
                                <Icon className={classNames(classes.icon, classes.iconVariant)} />
                                {message}
                            </span>
                        }
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={onClose}
                            >
                                <CloseIcon className={classes.icon} />
                            </IconButton>,
                        ]}
                        {...other}
                    //'You have entered invalid activation code. Please contact your club administration to get the correct activation code.'
                    />
                    : ''} */}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">Cancel</Button>
                <Button onClick={handleSubmit} color="primary">Activate</Button>
            </DialogActions>
        </Dialog>
    );
}

export default withMobileDialog()(withRouter(ActivationCode));
//TODO: implement activation failure message