import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import EventsService from './events-service';
import EventCard from './event-card';
import EventDialog from './event-dialog';
import useUpcomingEvents from '../../hooks/data/UseUpcomingEvents';
import { AuthContext } from "../../App";
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    control: {
        padding: theme.spacing(1),
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
});

function EventsIndex(props) {
    const [open, setOpen] = useState(false);
    const events = useUpcomingEvents();
    const { classes } = props;
    const { state: authState } = React.useContext(AuthContext);
    const theme = useTheme();
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('');
    }
    function saveEvent(eventData){
        console.log('Saving event:', eventData);
        if(eventData.values.id){
            //update
            const event = {...eventData.values, updatedBy: authState.user.id};
            EventsService.updateEvent(event)
                .finally(setOpen(false));
        }
        else {
            //insert
            let eventId = `${formatDate(eventData.values.when)}-${eventData.values.when.getHours()}${eventData.values.when.getMinutes()}-${eventData.values.type}`;
            if(eventData.values.type == "match" ){
                eventId = `${eventId}-${eventData.values.team}-${eventData.values.opponent}`;
            }
            const event = {...eventData.values, createdBy: authState.user.id, id:eventId};
            EventsService.addEvent(event)
                .finally(setOpen(false));
        }
        
    }

    return (<Grid container className={classes.root}>
        {authState.allowAddEvent
            ?
            <Fab color="secondary" aria-label="Add" className={classes.fab}>
                <AddIcon onClick={() => setOpen(true)} />
                <EventDialog open={open} fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
                    handleClose={() => setOpen(false)}
                    handleSaveEvent={(eventData) => saveEvent(eventData)} />
            </Fab>
            : ''
        }
        {events.map((item) =>
            <Grid item key={item.id}>
                <div className={classes.control}>
                    <EventCard event={item}
                        saveEvent={(eventData) => saveEvent(eventData)}
                    />
                </div>
            </Grid>
        )}
    </Grid>);
}

// function isAddEventAllowed(){
//     return (!!CurrentPlayer && !!CurrentPlayer.roles && (CurrentPlayer.roles.includes("admin") 
//         || CurrentPlayer.roles.includes("organizer")
//         || CurrentPlayer.roles.includes("captain")))
//     ? true
//     : false
// }

export default withStyles(styles)(EventsIndex);