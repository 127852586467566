import {useState, useEffect} from 'react';
import firebase from 'firebase';

function useUpcomingEvents(){
    const [events, setEvents] = useState([]);

    function handleEventsChange(events){
        setEvents(events);
    }

    useEffect(() => {
        firebase.firestore().collection("event")
            .where("when", ">", firebase.firestore.Timestamp.fromDate(new Date()))
            .onSnapshot((querySnapshot) => {
                    let events = [];
                    querySnapshot.forEach(doc =>
                        events.push({ id: doc.id, ...doc.data() }));
                    handleEventsChange(events);
                })
    }, [])

    return events;
}

export default useUpcomingEvents;