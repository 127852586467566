import React from 'react';
import { withStyles, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { firebaseApp } from '../../firebaseService';
//import useAuthUser from '../../hooks/UseAuthUser';
import {AuthContext} from '../../App';
const style = (theme) => ({
    button: {
        margin: theme.spacing(1),
    }
});



function LoginLogout(props){
    //const authUser = useAuthUser();
    const {state: authState, dispatch} = React.useContext(AuthContext);
    const { classes } = props;

    const LogoutButton = withRouter(
        ({ history }) => (<Button color="secondary" variant="contained" className={classes.button} onClick={() => (
            firebaseApp.auth().signOut()
                .then(() => dispatch({type: "LOGOUT"}))
                .then(() => {
                    history.push('/');
                })
                .catch((error) => console.error('logout error', error))
        )} >Log Out</Button>)
    );

    return authState.isAuthenticated
        ? <LogoutButton />
        : <Button color="secondary" variant="contained" className={classes.button} component={Link} to="/login">Log In</Button>
}

export default withStyles(style)(LoginLogout);

