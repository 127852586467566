import React, { useState } from 'react';

import firebase from 'firebase';
import { Redirect } from "react-router-dom";

import ActivationCode from './ActivationCode';
import { firestore } from '../../firebaseService';
import { withStyles } from '@material-ui/core/styles';
import { Grid} from '@material-ui/core';
import LoginDialog from './login-dialog';
import {AuthContext} from '../../App';

const styles = {
    card: {
        minWidth: 398,
        //maxWidth:400,
    },
}

function Login(props) {
    const LOGGEDOUT = 'LOGGEDOUT';
    //const LOGGEDIN = 'LOGGEDID';
    const NEWPLAYER = 'NEWPLAYER';
    const EXISTINGPLAYER = 'EXISTING';
    const [auth, setAuth] = useState(LOGGEDOUT)
    const [loginDialogState, setLoginDialogState] = useState(true);
    const {dispatch} = React.useContext(AuthContext);
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.

        // We will display Google and Facebook as auth providers.
        signInOptions: [
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ]
        ,
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: signInSuccess,
        }
    };

    function signInSuccess(authResult, redirectUrl) {
        setLoginDialogState(false);
        if (authResult.additionalUserInfo.isNewUser) {
            setAuth(NEWPLAYER);
        } else {
            //get player profile
            //console.log('authresult: ', authResult)
            
            firestore.collection("players").doc(authResult.user.uid).get().then((player) => {
                if (player.exists) {
                    console.log('player.exists:', player.id);
                    console.log(player.data());
                    dispatch({
                        type: "LOGIN",
                        payload: { id: authResult.user.uid, ...player.data() }
                    });
                    //setCurrentPlayer(player.id, { id: player.id, ...player.data() });
                    setAuth(EXISTINGPLAYER);
                } else {
                    //if not found then
                    //setCurrentPlayer();
                    //console.log('player not found');
                    setAuth(NEWPLAYER);
                    //return (<ActivationCode open={true} />);
                }
            }).catch(function (error) {
                //FIXME: Log error somewhere else
                console.log('Error getting players documents:', error);
            });
        }
        return false;
    }

    let component;
    switch (auth) {
        case NEWPLAYER:
            component = <ActivationCode open={true} />;
            break;
        case EXISTINGPLAYER:
            //console.log(CurrentPlayer);
            
            const { from } = props.location.state || { from: { pathname: "/" } };
            component = <Redirect to={from} />
            break;
        case LOGGEDOUT:
        default:
            component = <LoginDialog uiConfig={uiConfig} dialogState={loginDialogState} />
    }

    return (
        <Grid>
            <Grid item xs={12}>
                {component}
            </Grid>
        </Grid>

    );
}
export default withStyles(styles)(Login);

//TODO: On Logout take to login page
//TODO: Change Logout menu to person icon with dropdown menu to show logout option and profile
//TODO: Firebase Login screen styling
//TODO: Handle new user to capture profile info and add member entry?
//TODO: Code checking to verify if a valid member or email confirmation? 