import React from 'react';
//import logo from './logo.svg';
import './App.css';
import NavBar from './components/common/nav-bar';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import EventsIndex from './components/events/events-index';
import PlayingXIIndex from './components/playingXI';
import HomeIndex from './components/home';
import { Paper, Typography } from '@material-ui/core';
import Login from './components/auth/Login';
import PrivateRoute from './components/common/private-route';
import withRoot from './withRoot';
import Privacy from './components/privacy/privacy';
export const AuthContext = React.createContext();
const userFromStorage = JSON.parse(localStorage.getItem("user"));

const initalState = getInitialState();

function getInitialState(){
  let allowAddEvent = !!userFromStorage ? allowedToAddEvent(userFromStorage.roles) : false;
  let allowPickTeam = !!userFromStorage ? allowedToPickTeam(userFromStorage.roles) : false;
  return {
    isAuthenticated: !!userFromStorage,
    user: userFromStorage,
    allowAddEvent,
    allowPickTeam
  };
}
console.log(initalState);

function allowedToAddEvent(roles){
  return (roles && roles.length > 0) && (roles.includes("captain")
        || roles.includes("organizer")
        || roles.includes("admin"));
}

function allowedToPickTeam(roles){
  return (roles && roles.length > 0) && (roles.includes("captain")
        || roles.includes("organizer")
        || roles.includes("admin"));
}


const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      console.log('auth reducer login');
      localStorage.setItem("user", JSON.stringify(action.payload));
      
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        allowAddEvent: allowedToAddEvent(action.payload.roles),
        allowPickTeam: allowedToPickTeam(action.payload.roles)
      };
    case "LOGOUT":
      console.log('auth reducer logout');
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        allowPickTeam: false,
        allowAddEvent: false
      };
    default:
      return state;
  }
};

function App(props) {
  const [state, dispatch] = React.useReducer(reducer, initalState);
  return <AuthContext.Provider value={{state, dispatch}}>
    <BrowserRouter>
      <React.Fragment>
        <NavBar />
        <Switch>
          <Route exact path="/" component={HomeIndex} />
          <Route path="/login" component={Login} />
          <PrivateRoute path='/events' component={EventsIndex} />
          <PrivateRoute path='/playingXI' component={PlayingXIIndex} />
          <Route path="/privacy" component={Privacy} />
          <Route component={NoMatch} />
        </Switch>
      </React.Fragment>
    </BrowserRouter>
  </AuthContext.Provider>
}
const NoMatch = ({ location }) => (
  <Paper>
    <Typography variant="h5" component="h3">
      404 - Page '{location.pathname}' not found!
    </Typography>
  </Paper>
);
export default withRoot(App);

//TODO: Fix login redirect with Activation Code as well
//FixMe: Fix data load with custom data hooks
