import React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Button, Toolbar, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';

import PropTypes from 'prop-types';
import withRoot from '../../withRoot';
import LoginLogout from '../auth/login-logout';

const styles = theme => ({
    root: {
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        display: 'block',
    },
    desktopMenu: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    mobileMenu: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    
});

const menuItems = [
    { text: 'Home', to: '/' },
    { text: 'Events', to: '/events' },
    { text: 'Playing XI', to: '/playingXI' }
]

function NavBar(props) {
    // const [mobileMenuStatus, setMobileMenuStatus] = useState(false);
    const { classes } = props;
    const popupState = usePopupState({variant: 'popover', popupId: 'mobile-menu'})
    // function handleMenuClick(target) {
    //     setMobileMenuStatus(false);
    // }
    return (
        <div className={classes.root} >
            <AppBar position='static'>
                <Toolbar>
                    <div className={classes.mobileMenu}>
                        <IconButton className={classes.menuButton}
                            color="inherit"
                            aria-label="Open drawer"
                            aria-owns='mobile-menu'
                            aria-haspopup="true"
                            {...bindTrigger(popupState)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu id='mobile-menu' {...bindMenu(popupState)}>
                            {
                                menuItems.map((item) => (
                                    <MenuItem key={item.text} onClick={popupState.close} component={Link} to={item.to}>{item.text}</MenuItem>
                                ))
                            }
                        </Menu>
                    </div>

                    <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                        Metroplex Cricket Club
                    </Typography>
                    <div className={classes.grow} />
                    <div className={classes.desktopMenu}>
                        {
                            menuItems.map((item) => (
                                <Button key={item.text} color="inherit" className={classes.button} component={Link} to={item.to}>{item.text}</Button>
                            ))
                        }
                    </div>
                    
                    <LoginLogout />
                </Toolbar>
            </AppBar>
        </div>
    )
}

NavBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavBar);
