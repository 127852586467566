import {useState, useEffect} from 'react';
import firebase from 'firebase';

function useEventAvailability(eventId){
    const [eventAvailability, setEventAvailability] = useState([]);
    const [availableList, setAvailableList] = useState([]);
    const [notAvailableList, setNotAvailableList] = useState([]);
    
    useEffect(() => {
        // EventsService.getEventAvailability(event.id)
        //     .then((eventAvailability) => setEventAvailability(eventAvailability))
        firebase.firestore().collection("availability").where("eventId", "==", eventId)
            .onSnapshot((docs) => {
                let availability = [];
                docs.forEach(doc =>
                    availability.push({ id: doc.id, ...doc.data() }));

                let available = availability.filter((ea) => ea.eventId === eventId && ea.available);
                let notAvailable = availability.filter((ea) => ea.eventId === eventId && !ea.available)
                setEventAvailability(availability);
                setAvailableList(available);
                setNotAvailableList(notAvailable);
            });
    }, [])

    return {
        eventAvailability,
        availableList,
        notAvailableList
    };
}

export default useEventAvailability;