import { firestore } from '../../firebaseService';
import firebase from "firebase";

class EventsService {

    static getMyEventAvailability(playerId, eventId) {
        console.log(`playerId:${playerId} eventId:${eventId}`);
        return new Promise((resolve, reject) => {
            firestore.collection("availability").doc(`${eventId}-${playerId}`).get()
                .then((doc) => {
                    if (doc.exists) {
                        console.log('availability:', doc.data())
                        resolve({ id: doc.id, ...doc.data() });
                    } else {
                        resolve();
                    }
                }).catch(function (error) {
                    //FIXME: Log error somewhere else
                    console.error('Error getting availability document:', error);
                    reject(error);
                });
        });
    }

    static getUpcomingEvents() {
        return new Promise((resolve, reject) => {
            firestore.collection("event").where("when", ">", firebase.firestore.Timestamp.fromDate(new Date())).get()
                .then((querySnapshot) => {
                    let events = [];
                    querySnapshot.forEach(doc =>
                        events.push({ id: doc.id, ...doc.data() }));
                    resolve(events);
                }).catch(function (error) {
                    //FIXME: Log error somewhere else
                    console.error('Error getting events documents:', error);
                    reject(error);
                });
        });
    }

    static getEventAvailability(eventId) {
        return new Promise((resolve, reject) => {
            firestore.collection("availability").where("eventId", "==", eventId)
                .onSnapshot((docs) => {
                    let availability = [];
                    docs.forEach(doc =>
                        availability.push({ id: doc.id, ...doc.data() }));
                    resolve(availability);
                });
        });
    }

    static addEvent(eventData){
        const event = {createdOn: firebase.firestore.FieldValue.serverTimestamp(), ...eventData}
        return new Promise((resolve, reject) => {
            firestore.collection("event").doc(event.id).set(event)
                .then(() => resolve({...event}))
                .catch((error) => {
                    console.error("Error adding event: ", error);
                    reject(error);
                })
        })
    }

    static updateEvent(eventData){
        const event = {updatedOn: firebase.firestore.FieldValue.serverTimestamp(), ...eventData}
        return new Promise((resolve, reject) => {
            firestore.collection("event").doc(event.id).set(event)
                .then(() => resolve({...event}))
                .catch((error) => {
                    console.error("Error adding event: ", error);
                    reject(error);
                })
        })
    }

    static setAvailability(player, event, availability, available) {
        let availabilityId = `${event.id}-${player.id}`
        return new Promise((resolve, reject) => {
            if (!!availability) {
                //update
                let newAvailability = { ...availability };
                newAvailability.history.push({
                    givenOn: availability.givenOn,
                    available: availability.available
                });
                newAvailability.available = available;
                newAvailability.givenOn = firebase.firestore.FieldValue.serverTimestamp()
                firestore.collection("availability").doc(availability.id).set(newAvailability)
                    .then(() => {
                        console.log("Availability updated.");
                        firestore.collection("availability").doc(availability.id).get()
                            .then((doc) => resolve({ id: doc.id, ...doc.data() }))
                            .catch((error) => {
                                console.error("Error refreshing availability post update.", error);
                                reject(error);
                            })
                            ;
                    })
                    .catch((error) => {
                        console.error("Error updating availability", error);
                        reject(error);
                    })
            } else {
                //insert
                let newAvailability = {
                    eventId: event.id,
                    playerId: player.id,
                    playerName: player.displayName,
                    playerPhotoURL: player.photoURL,
                    available: available,
                    givenOn: firebase.firestore.FieldValue.serverTimestamp(),
                    history: []
                };
                firestore.collection("availability").doc(availabilityId).set(newAvailability)
                    .then(() => {
                        console.log("Availability udpated.");
                        resolve({ id: availabilityId, ...newAvailability });
                    })
                    .catch((error) => {
                        console.error("Couldn't add availability", error);
                        reject(error);
                    });
            }
        })
    }
}
export default EventsService;