import React, { useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    FormControl, InputLabel, Grid, NativeSelect, TextField, Button
} from "@material-ui/core";
//import useFormInput from '../../hooks/UseFormInput';
import { useFormState } from 'react-use-form-state';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker } from "@material-ui/pickers";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    formControl: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
}));
function EventDialog(props) {
    const { event, handleClose, handleSaveEvent } = props;
    const { fullScreen, open } = props;
    //const eventData = useFormInput(!event ? { type: "", team: "", when: new Date() } : event);
    const [formState, { raw, select, text }] = useFormState(!!event ? {...event, when: event.when.toDate()} : { when: new Date() });
    const classes = useStyles();

    const teamControl =
        <FormControl required className={classes.formControl}>
            <InputLabel shrink htmlFor="team">Team</InputLabel>
            <NativeSelect
                {...select('team')}
                inputProps={{
                    name: 'team',
                    id: 'team'
                }}
            >
                <option value="" disabled>Select Team</option>
                <option value={"Hindustani"}>Hindustani</option>
                <option value={"Metroplex"}>Metroplex</option>
                <option value={"Metroplex T20"}>Metroplex T20</option>
            </NativeSelect>
        </FormControl>

    const opponentControl =
        <FormControl required className={classes.formControl}>
            <InputLabel shrink htmlFor="opponent">Opponent</InputLabel>
            <TextField
                inputProps={{
                    name: 'opponent',
                    id: 'opponent'
                }}
                {...text('opponent')} />
        </FormControl>

    function handleSubmit(e) {
        e.preventDefault();
    };


    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            aria-labelledby="add-player-title"
        >
            <DialogTitle id="add-player-title">Add/Edit Event</DialogTitle>
            <DialogContent>
                {/* <DialogContentText>
                    To create new event to get availability, fill all the fields below and click Save.
                </DialogContentText> */}
                <div className={classes.paper}>
                    {/* <form onSubmit={handleSubmit} className={classes.form}> */}
                    <FormControl required className={classes.formControl}>
                        <InputLabel shrink htmlFor="event-type">Event Type</InputLabel>
                        <NativeSelect
                            {...select('type')}
                            autoFocus
                            inputProps={{
                                name: 'type',
                                id: 'event-type'
                            }}
                        >
                            <option value="" disabled>Select Event Type</option>
                            <option value={"match"}>Match</option>
                            <option value={"practice"}>Practice</option>
                        </NativeSelect>
                    </FormControl>
                    {formState.values.type === "match"
                        ? teamControl
                        : ''
                    }
                    {formState.values.type === "match"
                        ? opponentControl
                        : ''
                    }
                    <FormControl required className={classes.formControl}>
                        <InputLabel shrink htmlFor="where">Where</InputLabel>
                        <TextField
                            inputProps={{
                                name: 'where',
                                id: 'where'
                            }}
                            {...text('where')} />
                    </FormControl>
                    <FormControl required className={classes.formControl}>
                        <InputLabel shrink htmlFor="when">When</InputLabel>
                        <DateTimePicker
                            format="EEE MMM dd, yyyy hh:mm a"
                            disablePast
                            inputProps={{
                                name: 'when',
                                id: 'when'
                            }}
                            {...raw('when')} />
                    </FormControl>

                    {/* </form> */}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button variant="outlined" onClick={() => handleSaveEvent(formState)} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EventDialog;